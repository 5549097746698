<template>
  <Modal
    v-model="isActiveModal"
    sm
    @ok="SET_MIDDLEWARE"
    :title="$t('transactions')"
  >
    <section class="vid-title-sec">
      <div
        class="alert"
        :class="`alert-${alertBox.variant}`"
        v-if="alertBox.status"
      >
        {{ alertBox.message }}
      </div>
      <div class="container">
        <div v-if="product.isActive === 1">
          {{ $t("removeProduct") }}
        </div>
        <div v-else>
          {{ $t("reactiveProduct") }}
        </div>
      </div>
    </section></Modal
  >
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
export default {
  components: { Modal },
  props: {
    product: {
      default: () => {},
    },
    productIndex: {
      default: 0,
    },
  },
  data() {
    return {
      isActiveModal: false,
      alertBox: { status: false },
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_CATEGORY_ACTION_FROM_QUERY();
      },
    },
    isActiveModal(data) {
      if (!data)
        this.$router.replace({
          query: { ...this.$route.query, modal: "closed" },
        });
    },
  },
  methods: {
    SET_MIDDLEWARE(loading) {
      if (this.product.isActive === 1) this.SEND_TO_PUBLISH_PRODUCT(loading);
      else this.SEND_TO_PUBLISH_PRODUCT(loading);
    },
    async SEND_TO_REMOVE_PRODUCT(loading) {
      loading(true);
      this.alertBox = await this.$store.dispatch("SEND_TO_REMOVE_PRODUCT", {
        productIndex: this.productIndex,
        id: this.product.id,
        path: this.product.type.storePath,
        commitRemovePath: this.product.type.commitRemovePath,
      });
      loading(false);
      this.isActiveModal = false;
      setTimeout(() => {
        if (this.alertBox.variant === "success") {
          this.isActiveModal = false;
          // this.$router.push(this.product.type.domain);
        }
      }, 2500);
    },
    // ürünü güncelle
    async SEND_TO_PUBLISH_PRODUCT(loading) {
      const fd = new FormData();
      fd.append("IsActive", this.product.isActive === 1 ? 0 : 1);
      fd.append("Description", this.product.description);
      fd.append("LangCode", this.product.langCode);
      fd.append("Overview", this.product.overview);
      fd.append("Title", this.product.title);
      fd.append("UserId", this.product.userId);
      fd.append("CategoryId", this.product.category.id);
      fd.append("PriceTRY", this.product.priceTRY);
      fd.append("PriceUSD", this.product.priceUSD);
      fd.append("PriceEUR", this.product.priceEUR);
      fd.append("DiscountTRY", this.product.discountTRY);
      fd.append("DiscountEUR", this.product.discountEUR);
      fd.append("DiscountUSD", this.product.discountUSD);
      fd.append("Level", this.product.level);
      fd.append("IsShowcase", this.product.isShowcase);
      fd.append("IsBuyable", this.product.isBuyable);
      fd.append("IsLocked", this.product.isLocked);
      fd.append("IsOffer", this.product.isOffer);
      fd.append("IsSharable", this.product.isSharable);
      fd.append("AccessDuration", this.product.accessDuration);
      fd.append("VideoPath", this.product.videoPath);
      fd.append("HiddenInfo", this.product.hiddenInfo);
      if (this.product.type.type === 3) {
        fd.append("Processes", this.product.processes);
      }
      if (this.product.type.type === 4) {
        fd.append("BookGenre", this.product.bookGenre);
        fd.append("BookISBN", this.product.bookISBN);
        fd.append("BookPrintInfo", this.product.bookPrintInfo);
        fd.append("PageCount", this.product.pageCount);
      }
      loading(true);
      const response = await this.$store.dispatch(
        this.product.type.dispatchUpdatePath,
        {
          fd,
          form: {
            ...this.product,
            isActive: this.product.isActive === 1 ? 0 : 1,
            id: this.product.id,
          },
        }
      );
      loading(false);
      if (response) {
        setTimeout(() => {
          this.isActiveModal = false;
        }, 1000);
      }
    },
    SET_CATEGORY_ACTION_FROM_QUERY() {
      if (this.$route.query.modal)
        if (this.$route.query.modal === "activated") this.isActiveModal = true;
    },
  },
  created() {
    this.SET_CATEGORY_ACTION_FROM_QUERY();
  },
};
</script>

<style></style>
