<template>
  <div>
    <ProductIsNotActive
      v-if="product.isActive === 0"
      :product="product"
    ></ProductIsNotActive>
    <!-- <Banner :title="product.name" :src="$api.image(product.imagePath)"></Banner> -->
    <!--channel-cover end-->
    <section class="videso_section">
      <Info
        :editUrl="{ path: 'detail' }"
        :viewAllUrl="$route.fullPath"
        :avatar="$api.image(product.imagePath)"
        :publish-button="product.isActive === 0 ? true : false"
        @publish="PUBLISH_BUTTON"
        no-search
        :title="product.title"
      ></Info>
      <!--info-pr-sec end-->
      <Tabs :tab="tab" @click:tab="SET_CLICK_TAB" :fields="tabFields"></Tabs>
      <div class="tab-content p-0" id="myTabContent">
        <component
          :productIndex="productIndex"
          :key="compKey"
          :product="product"
          :type="type"
          :is="tab.component"
        ></component>
      </div>
    </section>
    <IsActiveProductModal
      :productIndex="productIndex"
      :product="product"
    ></IsActiveProductModal>
    <!--Featured Videos end-->
  </div>
</template>

<script>
import Info from "../List/Info.vue";
import Tabs from "./Tabs.vue";
import Banner from "../List/Banner.vue";
import TabsFields from "./TabsFields";
import ProductIsNotActive from "./ProductIsNotActive.vue";
import IsActiveProductModal from "./IsActiveProductModal.vue";

export default {
  components: {
    Banner,
    Info,
    Tabs,
    ProductDetail: () => import("./ProductDetail.vue"),
    EventDates: () => import("./EventDates.vue"),
    Videos: () => import("./VideosV2.vue"),
    UserListOfEvent: () => import("./UserListOfEvent.vue"),
    Coupons: () => import("./Coupons.vue"),
    UploadedItems: () => import("./../Assignments/index.vue"),
    Comments: () => import("./Comments.vue"),
    SimilarProducts: () => import("./SimilarProducts.vue"),
    ProductIsNotActive,
    IsActiveProductModal,
  },
  mixins: [TabsFields],
  props: {
    productIndex: {
      default: -1,
    },
    product: {
      default: () => {},
    },
    type: {
      default: () => {},
    },
  },
  data() {
    return {
      tab: { id: 1, component: "ProductDetail", path: "detail" },
      tabFields: [],
      compKey: 1,
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_TAB_BY_QUERY();
      },
    },
  },
  methods: {
    PUBLISH_BUTTON() {
      this.$router.replace({
        query: { ...this.$route.query, modal: "activated" },
      });
    },
    SET_CLICK_TAB(field) {
      this.compKey += 1;
      this.$router.push({
        query: { ...this.$route.query, path: field.path },
      });
    },
    SET_TAB_BY_QUERY() {
      if (this.$route.query.path) {
        const field = this.tabFields.find(
          (et) => et.path === this.$route.query.path
        );
        if (field) this.tab = field;
        else if (this.type.typeTable === 3)
          this.tab = { id: 2, component: "EventDates", path: "eventdates" };
        else this.tab = { id: -1, component: "ProductDetail", path: "detail" };
      } else {
        this.tab = this.tabFields[0];
      }
    },
    SET_TAB_FIELDS() {
      this.tabFields = this[this.type.tab];
    },
  },
  created() {
    // önce tab fields oluştur
    this.SET_TAB_FIELDS();
  },
  mounted() {
    // sonra active tab belirle
    this.SET_TAB_BY_QUERY();
    if (this.type.typeTable === 3 && !this.$route.query.path)
      this.tab = { id: 2, component: "EventDates", path: "eventdates" };
  },
};
</script>
