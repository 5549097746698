import i18n from "../../plugins/i18n";

export default {
  data() {
    return {
      eventTabs: [
        {
          id: 2,
          component: "EventDates",
          title: i18n.t("eventTabs.eventDate"),
          path: "eventdates",
          icon: "calender",
        },
        {
          id: 1,
          component: "ProductDetail",
          title: i18n.t("eventTabs.detail"),
          path: "detail",
          icon: "pencil",
        },
        {
          id: 3,
          component: "Coupons",
          icon: "star",
          title: i18n.t("eventTabs.coupons"),
          path: "coupons",
        },
        {
          id: 13,
          component: "Comments",
          icon: "feedback",
          title: i18n.t("eventTabs.comments"),
          path: "comments",
        },
        {
          id: 101,
          component: "SimilarProducts",
          icon: "like",
          title: i18n.t("eventTabs.similarProducts"),
          path: "similarProducts",
        },
      ],
      courseTabs: [
        {
          id: 1,
          component: "ProductDetail",
          title: i18n.t("courseTabs.detail"),
          path: "detail",
          icon: "pencil",
        },
        {
          id: 5,
          component: "Videos",
          icon: "play_list",
          title: i18n.t("courseTabs.videos"),
          path: "videos",
        },
        {
          id: 4,
          component: "UserListOfEvent",
          title: i18n.t("courseTabs.userList"),
          path: "userlist",
          icon: "user",
        },
        {
          id: 3,
          component: "Coupons",
          icon: "star",
          title: i18n.t("courseTabs.coupons"),
          path: "coupons",
        },
        {
          id: 13,
          component: "Comments",
          icon: "feedback",
          title: i18n.t("courseTabs.comments"),
          path: "comments",
        },
        {
          id: 101,
          component: "SimilarProducts",
          icon: "like",
          title: i18n.t("courseTabs.similarProducts"),
          path: "similarProducts",
        },
      ],
      bookTabs: [
        {
          id: 1,
          component: "ProductDetail",
          title: i18n.t("bookTabs.detail"),
          path: "detail",
          icon: "pencil",
        },
        {
          id: 4,
          component: "UserListOfEvent",
          title: i18n.t("bookTabs.userList"),
          path: "userlist",
          icon: "user",
        },
        {
          id: 14,
          component: "UploadedItems",
          title: i18n.t("bookTabs.uploadedItems"),
          path: "uploadedItems",
          icon: "load",
        },
        {
          id: 3,
          component: "Coupons",
          icon: "star",
          title: i18n.t("bookTabs.coupons"),
          path: "coupons",
        },
        {
          id: 13,
          component: "Comments",
          icon: "feedback",
          title: i18n.t("bookTabs.comments"),
          path: "comments",
        },
        {
          id: 101,
          component: "SimilarProducts",
          icon: "like",
          title: i18n.t("bookTabs.similarProducts"),
          path: "similarProducts",
        },
      ],
      seanceTabs: [
        {
          id: 1,
          component: "ProductDetail",
          title: i18n.t("seanceTabs.detail"),
          path: "detail",
          icon: "pencil",
        },
        {
          id: 4,
          component: "UserListOfEvent",
          title: i18n.t("seanceTabs.userList"),
          path: "userlist",
          icon: "user",
        },
        {
          id: 14,
          component: "UploadedItems",
          title: i18n.t("seanceTabs.uploadedItems"),
          path: "uploadedItems",
          icon: "load",
        },
        {
          id: 3,
          component: "Coupons",
          icon: "star",
          title: i18n.t("seanceTabs.coupons"),
          path: "coupons",
        },
        {
          id: 13,
          component: "Comments",
          icon: "feedback",
          title: i18n.t("seanceTabs.comments"),
          path: "comments",
        },
        {
          id: 101,
          component: "SimilarProducts",
          icon: "like",
          title: i18n.t("seanceTabs.similarProducts"),
          path: "similarProducts",
        },
      ],
      blogTabs: [
        {
          id: 1,
          component: "ProductDetail",
          title: i18n.t("blogTabs.detail"),
          path: "detail",
          icon: "pencil",
        },
        {
          id: 13,
          component: "Comments",
          icon: "feedback",
          title: i18n.t("blogTabs.comments"),
          path: "comments",
        },
        {
          id: 101,
          component: "SimilarProducts",
          icon: "like",
          title: i18n.t("blogTabs.similarProducts"),
          path: "similarProducts",
        },
      ],
    };
  },
};
