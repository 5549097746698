<template>
  <div class="product-is-not-active alert-danger">
    <p><i class="icon-notification"></i> {{ $t("clickForPublish") }}</p>
    <button
      @click="
        $router.replace({ query: { ...$route.query, modal: 'activated' } })
      "
      class="donate btn-pill btn"
    >
      {{ $t("publish") }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      default: () => {},
    },
  },
};
</script>
