<template>
  <div class="history-lst tbY">
    <div class="container">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li
          @click="$emit('click:tab', field)"
          v-for="(field, i) in fields"
          :key="i"
          class="nav-item"
        >
          <a
            class="nav-link"
            :class="{ active: field.path === tab.path }"
            id="home_tab"
            data-toggle="tab"
            href="javascript:void(0)"
            role="tab"
            aria-controls="home_tab"
            aria-selected="true"
          >
            <i :class="`icon-${field.icon}`"></i> {{ field.title }}</a
          >
        </li>
      </ul>
      <!--nav-tabs end-->
      <div class="clearfix"></div>
    </div>
  </div>
  <!--history-lst end-->
</template>

<script>
export default {
  props: {
    fields: {
      default: () => [],
    },
    tab: {
      default: () => {
        return {
          path: "",
        };
      },
    },
  },
};
</script>
