<template>
  <div>
    <PageLoading v-if="getProductIsLoading"></PageLoading>
    <div v-else-if="getProduct.id === -1">
      <NoFound></NoFound>
    </div>
    <Product
      :productIndex="getProductIndex"
      :type="type"
      :product="getProduct"
      v-else
    ></Product>
  </div>
</template>

<script>
import Product from "../components/Product";
import Types from "../components/Type/Types";
import NoFound from "../components/Shared/NoFound.vue";
import PageLoading from "../components/Shared/PageLoading.vue";
export default {
  components: { Product, NoFound, PageLoading },
  mixins: [Types],
  data() {
    return {
      id: -1,
      path: "",
      type: {},
    };
  },
  computed: {
    getProductIsLoading() {
      return this.$store.state.Products.productIsLoading;
    },
    getProductIndex() {
      const product = this.$store.state.Products.products.findIndex(
        (p) => p.id === Number(this.id)
      );
      if (product !== -1) return product;
      else return -1;
    },
    getProduct() {
      const product = this.$store.state.Products.products.find(
        (p) => p.id === Number(this.id)
      );
      if (product) return product;
      else return { id: -1 };
    },
  },
  methods: {
    // GET PRODUCT
    GET_PRODUCT() {
      try {
        [this.path, this.id] = this.$route.params.id.split("-");
        const type = this.Types.find((t) => t.statePath === this.path);
        if (type) {
          this.type = type;
          this.$store.dispatch("GET_PRODUCT", {
            path: `${type.storePath}/${this.id}`,
            id: Number(this.id),
            type,
          });
        } else this.$router.push("/error");
      } catch (error) {
        this.$router.push("/error");
      }
    },
  },
  mounted() {
    this.GET_PRODUCT();
  },
};
</script>
